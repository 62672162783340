* {
  margin: 0;
  padding: 0;
}

:root {
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

/* globals */
body {
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: "Alegreya", serif;
  font-family: "Lora", serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Playfair Display", serif;
  font-family: "Roboto", sans-serif;
  font-family: "Bellefair", sans-serif;
  line-height: 1.4;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
